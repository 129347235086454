@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7po7wy');
  src:  url('fonts/icomoon.eot?7po7wy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7po7wy') format('truetype'),
    url('fonts/icomoon.woff?7po7wy') format('woff'),
    url('fonts/icomoon.svg?7po7wy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-envelope-open:before {
  content: "\e900";
}
.icon-mail-envelope-closed:before {
  content: "\e901";
}
.icon-mail-envelope-closed2:before {
  content: "\e902";
}
.icon-search2:before {
  content: "\e903";
}
.icon-document-file-xls:before {
  content: "\e904";
}
.icon-envelope:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-phone:before {
  content: "\e942";
}
.icon-location:before {
  content: "\e947";
}
.icon-user:before {
  content: "\e971";
}
.icon-search:before {
  content: "\e986";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-briefcase:before {
  content: "\e9ae";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-file-excel:before {
  content: "\eae2";
}
