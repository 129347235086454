.PricePage {
	margin-top: 2rem;
}
.PriceList {
	
	li {
		padding: 1rem;
		border-bottom: 1px solid $grey-light;
	}
}

.HeaderWrap {
	background: #e6e6e6
}


.Header {
	position: relative;

	&__logo {
		margin-left: 18px;
		margin-top: 10px;
		margin-bottom: 10px;
		display: block;
		width: 120px;
		height: 50px;
		text-decoration: none;
		background: url($img-url + 'logo.png') no-repeat center center;
		background-size: contain;
		border: none;

		&:hover {
			border: none;
		}

		@include screen(320px, 960px) { 
			width: 100px;
		}

		@include max-screen(320px) {

		}
	}

	&__menu {
		line-height: 24px;
		padding-top: 24px;

		@include max-screen(320px) {
			display: none;
		}
	}
	&__menuItem {
		margin-right: 1rem;
		font-size: 1rem;

		@include screen(320px, 960px) { 
			font-size: 0.85rem;
			margin-right: 0.5rem;
		}
	}

	&__auth {
		text-align: right;
		
		a {
			font-weight: bold;
		}
		position: absolute;
		height: 32px;
		top: 0px;
		right: 10px;
		bottom: 0px;
		margin: auto;
	}

	
}

$height_pl:320px;
$width_pl:620px;
$width_treangle:120px;

.PromoLine {
	background: $green;
	height: $height_pl;
	position: relative;

	&__textslab {
		padding-left: 3rem;
		padding-top: 2rem;
		z-index: 1;
		position: relative;
		h1 {
			color: $white;
			font-style: italic;
			font-size: 2rem;
			font-weight: 600;
			margin-top: 2rem;

			
			@include max-screen(320px) {
				font-size: 1.5rem;
			}

		}

		@include max-screen(320px) {
			background: rgba(0,0,0,0.5);
			padding-left: 1rem;
			height: 320px;
    		box-sizing: border-box;
		}
	}


	&__imageWrapp {
		position: relative;
		overflow: hidden;
		height: $height_pl;
		width: $width_pl;
		@include screen(320px, 960px) { 
			width:420px;
		}

		@include max-screen(320px) {
			width: 320px;
		}
		position: absolute;
		right: 0px;
		top:0px;
		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-bottom: $height_pl solid $green; 
			border-left: $width_treangle solid transparent;
			position: absolute;
			right: 0px;
			top: 0px;
		}

		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top:  $height_pl solid $green; 
			border-right: $width_treangle solid transparent;
			position: absolute;
			left: 0px;
			top: 0px;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		background: url($img-url + 'main.jpg') no-repeat bottom center;
		background-size: cover;
	}
}
