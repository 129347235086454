@import "../../../node_modules/sass-mediaqueries/_media-queries";
@import "../../../node_modules/normalize-css/normalize.css";
@import "../../../node_modules/lightslider/src/css/lightslider.css";
@import "../fonts/icomoon/style.css";

@import "_external/font-awesome.min.css";

@import "0_vars/vars_sait.scss";

@import "1_grid/gridM.scss";
@import "1_grid/gridL.scss";
//@import "1_grid/gridX.scss";

@import "2_base/base_main.scss";


@import "3_components/header.scss";
@import "3_components/popup.scss";


@import "4_product/main.scss";
@import "4_product/camp.scss";

