

.CampPage {
	padding-top: 24px;

	@include max-screen(320px) {
		padding-top: 2rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		box-sizing: border-box;
	}

	h1, h2, h3, h4 {
		font-weight: 400;
	}

	.h2 {
		font-size: 1.5rem;
		margin-bottom: 1rem;
		padding-right: 50%;

		@include max-screen(320px) {
			padding-right: 0;
			font-size: 1.2rem;
		}
	}
	a {
		color: $green !important;
	}
	p {
		@include max-screen(320px) {
			font-size: 0.85rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	ul {
		margin-top: 32px;
		li {
			padding-left: 40px;
			padding-bottom: 20px;
			position: relative; 
			&:before {
				content: "";
				position: absolute;
				left: 0px;
				top: 12px;
				width: 16px;
				height: 1px; background: $green-dark;
			}
		}
	}

	&__imageWrap {
		box-sizing: border-box;
		padding: 3px;
		border: 1px dashed $green;
		margin-top: 1.25rem;
	}

	&__image {
		width: 100%;
		height: 100%;

		&._image-1 {
			background: url($img-url + 'camp_1.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}

		&._image-2 {
			background: url($img-url + 'camp_2.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-3 {
			background: url($img-url + 'camp_3.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-4 {
			background: url($img-url + 'camp_4.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-5 {
			background: url($img-url + 'camp_5.jpg') no-repeat bottom left;
			background-size: cover;
			height: 260px;
		}
	}


	form {
		padding: 20px;
		background: #9db5bd;
		margin-top: 20px;
		margin-bottom: 54px;
		input, select {
			width: 100%;
			height: 42px;
		}
		button {
			width: 100%;
			margin-top: 40px;
			height: 42px;
		}

		.tab-pane {
			display: none;
			&.active {
				display: block,
			}
		} 
	}
}