@charset "utf-8";
/*
    FONTS SET
*/

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Regular'), local('Roboto-Regular'), url('/public/fonts/roboto/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin'), local('Roboto-Thin'), url('/public/fonts/roboto/roboto-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'), url('/public/fonts/roboto/roboto-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('/public/fonts/roboto/roboto-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('/public/fonts/roboto/roboto-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black'), local('Roboto-Black'), url('/public/fonts/roboto/roboto-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Italic'), local('Roboto-Italic'), url('/public/fonts/roboto/roboto-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('/public/fonts/roboto/roboto-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('/public/fonts/roboto/roboto-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('/public/fonts/roboto/roboto-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('/public/fonts/roboto/roboto-thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('/public/fonts/roboto/roboto-blackitalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans';
    src: url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.eot');
    src: url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.woff2') format('woff2'),
        url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.woff') format('woff'),
        url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.ttf') format('truetype'),
        url('/public/fonts/pt_sans/pt_sans-web-regular-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.eot');
    src: url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.woff2') format('woff2'),
        url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.woff') format('woff'),
        url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.ttf') format('truetype'),
        url('/public/fonts/pt_sans/pt_sans-web-italic-webfont.svg#pt_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'PT Sans';
    src: url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.eot');
    src: url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.woff2') format('woff2'),
        url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.woff') format('woff'),
        url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.ttf') format('truetype'),
        url('/public/fonts/pt_sans/pt_sans-web-bold-webfont.svg#pt_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.eot');
    src: url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.woff2') format('woff2'),
        url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.woff') format('woff'),
        url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.ttf') format('truetype'),
        url('/public/fonts/pt_sans/pt_sans-web-bolditalic-webfont.svg#pt_sansbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}


html {
    height: 100%;

}


body {
    font-family: 'Roboto';
    font-weight: normal;
    position: relative;
    height: 100%;
    
}

input,
select,
textarea { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
select,button{cursor:pointer;}

input[disabled] {
      opacity: 0.5;
     -moz-opacity: 0.5; /* Netscape */
     -khtml-opacity: 0.5; /* Safari 1.x */
     opacity: 0.5; /* Для остальных браузеров */
     cursor: default;
 }




fieldset {
    margin: 0px;
    padding: 0px;
    border: 0px;
    position: relative;
    box-sizing: border-box;

    &.fluid {
        width: 100%;
        input { width: 100%; }
    }
    .error {
        color: red;
        font-size: 12px;
    }

    label {
        position: relative;
        font-size: 14px;
        color: $black;
        display: inline-block;
        line-height: 14px;
        margin-bottom: 10px;
        margin-top: 10px;

        &._important:after {
            content: "*";
            color: red;
            position: absolute;
            right: -8px;
            font-size: 12px;
            top: -2px;
        }
    }

    select {
        height: 32px;
        border: 2px solid #00a64d;
        background: #fff;
        border-radius: 5px;

      
        @include border-radius(5px); 
        color: $black;
        line-height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }

    textarea {
        border: 2px solid #00a64d;
        background: #fff;
        border-radius: 5px;
        color: $black;

        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }

    input {
        height: 32px;
        border: 2px solid #00a64d;
        background: #fff;
        border-radius: 5px;

      
        @include border-radius(5px); 
        color: $black;
        line-height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }

    .checkebox {
        cursor: pointer;
        margin-top:4px;
        margin-bottom: 4px;

        label {
            cursor: pointer;
            margin: 0px;
            padding: 0px;
            margin-left: 20px;
            overflow: hidden;
            white-space: nowrap;
        }
       
        &:before {
            content: "";
            width: 16px;
            height: 16px;
            background: $grey-light;
            @include border-radius(3px);
            position: absolute;
            left:0px;
            top:-4px;
            bottom: 0px;
            margin: auto 0;
        }

        &._checked {
            &:after {
                content: "\ea10";
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                position: absolute;
                left:3px;
                top:-1px;
                font-size: 12px;
                color:$black;
            }
        }
    }
}



button {
    background: $green;
    padding-left:20px;
    padding-right: 20px;
    text-align: center;
    line-height: 32px; 
    height: 32px;
    border: none;
    color: white;
    @include border-radius(5px);
    font-size: 14px;
    
    &:hover {
        color: white;
        background: $green-hover;
    };

    &._grey {
        background: white;
        border: 1px solid $grey;
        color: $black;
        box-sizing: border-box;

        &:hover {
            color: $black;
            background: $grey-light;
        };
    }

    &._black {
        background: $black;
        color: white;
        border: none;
        @include border-radius(0px);

        &:hover {
            background: $black-hover;
        }

    }
    &._big {
        padding-left: 52px;
        padding-right: 52px;
        font-size: 1.2rem;
        height: 52px;

        @include screen(320px, 960px) { 
            padding-left: 42px;
            padding-right: 42px;
            font-size: 1rem;
            height: 42px;
		}
    }

    &._white {
        background: transparent;
        color: $white;
        border: 1px solid $white;
    }
}


a._button {
    @extend button;
    line-height: 33px; 
}





a {
    cursor: pointer; 
    color: $black; 
    font-size: 14px;
    display: inline-block;
}

a:link,
a:visited,
a:hover,
a:active { text-decoration: none;  }
a:hover { text-decoration: none; color: $green-hover; border-bottom: 1px dashed;}



h1 {
    font-weight: 600;
    font-size: 26px;
    padding: 0px;
    margin: 20px 0px;
    font-weight: 600; 

}

span.h1 {
    font-weight: 600;
    font-size: 26px;
    padding: 0px;
    margin: 0px; 
    display: block;
    font-weight: 600; 
}

h2 {
    font-weight: 600;
    font-size: 24px;
    padding: 0px;
    margin: 0px;
    //font-family: 'Roboto Light';
}

span.h2 {
    font-weight: 600;
    font-size: 24px;
    padding: 0px;
    margin: 0px; 
    display: block;
    //font-family: 'Roboto Light';
}


h3 {
    font-weight: 600;
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    //font-family: 'Roboto Light';
}

span.h3 {
    font-weight: 600;
    font-size: 18px;
    padding: 0px;
    margin: 0px; 
    display: block;
    //font-family: 'Roboto Light';
}




ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}






._disable {

    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 8 */
    filter: alpha(opacity=50); /* IE 5-7 */
    -moz-opacity: 0.5; /* Netscape */
    -khtml-opacity: 0.5; /* Safari 1.x */
    opacity: 0.5; /* Для остальных браузеров */
    cursor: default;

}

._hidden {
    display: none !important;
}

._clear {
    clear: both;
}
._relative {
    position: relative;
}

._center {
    text-align: center;
}

._error {
    border: 1px solid red !important; 
}


._align-right {
    text-align: right;
}

._bold {
    font-weight: 600;
}

._align_middle {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left:0px;
    right: 0px;
    margin: auto auto;
}

._verticalOffset {
    margin-top: 1rem;
}

._quiet {
    color: $grey;
}

._smallText {
    font-size: 0.85rem;
}

