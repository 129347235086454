

    /* `Grid >> Children (Alpha ~ First, Omega ~ Last)
      ----------------------------------------------------------------------------------------------------*/
      $_name: L;
      $_W: 24*40px;
  
      @include min-screen($_W) {
          
          body {
              min-width: $_W;
          }
          
          .container_24 {    
      
              margin-left: auto;
              margin-right: auto;
      
              width: $_W;
              min-width: $_W;
      
              &.restrict {
                  width: auto;
                  margin: 0;
              }
               
              @for $i from 1 through 24 {
                  .grid_#{$_name}#{$i} {
                        display: block;
                        float: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        box-sizing: border-box;
                  }
  
                  .grid_#{$_name}#{$i} { width: $i*$_W/24;}
  
                  .prefix_#{$_name}#{$i} { padding-left: $i*$_W/24; }
  
                  .suffix_#{$_name}#{$i} { padding-right: $i*$_W/24; }
  
                  .push_#{$_name}#{$i} { 
                      position: relative;
                      left: $i*$_W/24;
                  }
  
                  .pull_#{$_name}#{$i} { 
                      position: relative; 
                      left: -$i*$_W/24;
                  }
  
                  .pop_#{$_name}#{$i} { 
                      position: relative;
                      right: $i*$_W/24;
                  }
              }
      
                 
          
          }
  
      
          .clear {
              clear: both!important;
              display: block;
              overflow: hidden;
              visibility: hidden;
              width: 0;
              height: 0;
          }
          
          
          .clearfix:before,
          .clearfix:after,
          .container_24:before,
          .container_24:after {
              content: '.';
              display: block;
              overflow: hidden;
              visibility: hidden;
              font-size: 0;
              line-height: 0;
              width: 0;
              height: 0;
          }
          
          .clearfix:after,
          .container_24:after {
              clear: both;
          }
          
          
          .clearfix,
          .container_24 {
              zoom: 1;
          }
          
          .alpha {
              padding-left: 0;
          }
          
          .omega {
              padding-right: 0;
          }
      }
      
      
      