/*
   START VARS SET
*/
    $img-url: "../img/";

    $black: #231f20;
    $black-light: #535252;
    $black-hover: #535252;
    $white: #ffffff;
    $grey-dark: #535252;
    $grey: #9c9c9c;
    $grey-pale: #b9b9b9;
    $grey-light: #ececec;
    $green: #00a64d;
    $green-hover: #029848;
    $green-dark: #029848;
    $orange: #ffac35;
    $red:#ff3535;




/*
   END VARS SET
*/




/*
    MIXIN
*/

    @mixin border-radius($radius) {
      -webkit-border-radius: $radius;
         -moz-border-radius: $radius;
          -ms-border-radius: $radius;
              border-radius: $radius;
    }

    @mixin border-radius-no() {
      -webkit-border-radius: 0px;
         -moz-border-radius: 0px;
          -ms-border-radius: 0px;
              border-radius: 0px;
    }


    @mixin border-radius-left($radius) {
      -webkit-border-radius: $radius 0px 0px $radius;
         -moz-border-radius: $radius 0px 0px $radius;
          -ms-border-radius: $radius 0px 0px $radius;
              border-radius: $radius 0px 0px $radius;
    }

    @mixin border-radius-right($radius) {
      -webkit-border-radius: 0px $radius $radius 0px;
         -moz-border-radius: 0px $radius $radius 0px;
          -ms-border-radius: 0px $radius $radius 0px;
              border-radius: 0px $radius $radius 0px;
    }

    @mixin box-shadow($HL, $VL , $BR , $SR, $O){
        -webkit-box-shadow: $HL $VL $BR $SR rgba(0,0,0,$O);
        -moz-box-shadow: $HL $VL $BR $SR rgba(0,0,0,$O);
        box-shadow: $HL $VL $BR $SR rgba(0,0,0,$O);
    }

    @mixin rotate($angle) {
        -moz-transform: rotate($angle); /* Для Firefox */
        -ms-transform: rotate($angle); /* Для IE */
        -webkit-transform: rotate($angle); /* Для Safari, Chrome, iOS */
        -o-transform: rotate($angle); /* Для Opera */
        transform: rotate($angle);
    }


/*
    END MIXIN
*/



