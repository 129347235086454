

.MainPage {
	.HeaderWrap {
		background: white;
	}

	@include max-screen(320px) {
		padding-top: 2rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		box-sizing: border-box;
	}

	.h2 {
		font-size: 1.5rem;
		margin-bottom: 1rem;
		padding-right: 50%;
		margin-top: 3rem;

		@include max-screen(320px) {
			padding-right: 0;
			font-size: 1.2rem;
		}
	}
	p {
		
		@include max-screen(320px) {
			font-size: 0.85rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	&__imageWrap {
		box-sizing: border-box;
		padding: 3px;
		border: 1px dashed $green;
		margin-top: 1.25rem;
	}

	&__image {
		width: 100%;
		height: 100%;

		

		&._image-1 {
			background: url($img-url + 'missia_2.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}

		&._image-2 {
			background: url($img-url + 'missiya_1.png') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-3 {
			background: url($img-url + 'missia_3.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-4 {
			background: url($img-url + 'missia_4.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
		&._image-5 {
			background: url($img-url + 'missia_5.jpg') no-repeat top left;
			background-size: cover;
			height: 260px;
		}
	}
}